$player-height: 65px;

.player_wrap {
  display: flex;
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $player-height;
  background-color: $white;

  -webkit-transition: height .2s;
  transition: height .2s;
  .jwplayer {
    flex-grow: 2;
  }
  .player_meta {
    flex-grow: 1;
    min-width: 450px;
  }
  &.empty_queue {
    #spco-player {
      display: none;
    }
    height: 0!important;
  }
}

.custom_controls {
  display: flex;
  -ms-flex-negative: 0;
  align-items: center;
  height: $player-height;
  width: 100px;
  > div {
    display: block;
    margin: 0; padding: 0;
    width: 16px;
    height: 16px;
    margin: 0 5px;
    cursor: pointer;
  }
  .playPause {
    height: 48px;
    width: 48px;
    border-radius: 48px;
    background-color: $red;
    display: flex;
    align-items: center;
    margin: 0;
    svg {
      margin: 0 auto;
    }
    &:hover {
      box-shadow: 0px 0px 7px 3px rgba($red,0.2);
    }
  }
  .prev, .next { visibility: hidden; }
  &.prev_enabled { .prev { visibility: visible; } }
  &.next_enabled { .next { visibility: visible; } }
}

.player_meta {
  border-left: 1px solid $border-light-gray;
  padding-left: 1rem;
  background-color: $white;
  color: $text-gray;
  display: flex;
  align-items: center;
  .queue_toggle {
    cursor: pointer;
    margin-right: .5rem;
  }
  .track_info {
    display: flex;
  }

  .image {
    overflow: hidden;
    width: 50px;
    img {
      max-width: none; /* Foundation */
      display: block;
      width: 112px;
      height: 63px;
      margin-left: -30px;
    }
  }
  .title {
    padding-top: .5rem;
    padding-left: .5rem;
    h5 {
      font-size: 1rem;
      color: $red;
      margin: 0;
      height: 1.3rem;
      overflow: hidden;
    }
    .composer {
      font-size: .85rem;
    }
  }

  @include breakpoint(small only) {
    min-width: 105px !important;
    .track_info .title {
      display: none!important;
    }
  }
}


.player_meta_popover {
  z-index: 1000;
  padding-bottom: 1rem;
  max-height: calc(100% - 65px);
  .Popover-body {
    padding: .5rem 0 0;
    display: inline-flex;
    flex-direction: column;
    background: $spco-white;
    color: $dark-gray;
    border-radius: 0.3rem;
    max-height: 100%;
  }
  .Popover-tipShape {
    fill: $spco-white;
  }
}

.queue_popup {
  min-width: 15rem;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  h5 {
    font-family: $secondary-font-family;
    color: $text-gray;
    @include thick-caps;
    padding-bottom: .3rem;
    border-bottom: 1px solid $border-light-gray;
    margin-left: .5rem;
    margin-right: .5rem;
    position: absolute;
    background-color: $spco-white;
    top: .3rem;
    width: calc(100% - 1rem);
  }
  ul {
    padding-top: 1.7rem;
    li {
      cursor: pointer;
      font-size: .9rem;
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
      justify-content: space-between;
      padding: .2rem .5rem;
      &.current {
        background-color: #eee;
      }
      .image {
        overflow: hidden;
        width: 50px;
        img {
          max-width: none; /* Foundation */
          display: block;
          width: 90px;
          height: 50px;
          margin-left: -35px;
        }
      }
      .title {
        flex: 2;
        padding-left: .3rem;
      }
      strong {
        color: $red;
        display: block;
      }
      .delete {
        /* temporarily */
        cursor: pointer;
        display: inline-block;
        background-color: $lighter-gray;
        color: darken($dark-gray, 5%);
        $dim: 1.1rem;
        width: $dim;
        height: $dim;
        border-radius: $dim;
        text-align: center;
        line-height: $dim;
        font-size: .7rem;
        font-family: $secondary-font-family;
        font-weight: 900;
        margin-left: .5rem;
        &:hover {
          background-color: $red;
          color: $white;
        }
      }
    }
  }
}

#spco-player {
  background-color: $white!important;
  &, .jw-controls {
    overflow: visible!important;
  }

  .jw-background-color {
    background-color: $white;
  }
  .jw-button-color {
    color: $red;
  }
  .jw-rail {
    background-color: $light-gray;
  }
  .jw-progress {
    background-color: $red;
  }
  .jw-knob {
    background-color: $red;
  }
  .jw-text {
    color: $dark-gray;
    white-space: nowrap;
  }
  .jw-text-elapsed {
    color: $red;
  }
  .jw-media {
    display: none;
  }
  .jw-icon-playback {
    height: $player-height;
    padding-top: 14px;
  }
  .jw-icon-fullscreen, .jw-icon-hd,
  .jw-icon-playback, .jw-icon-rewind, .jw-icon-pip {
    display: none;
  }

  /* native apps */
  .jw-display-controls.jw-reset {
    display: none;
  }

  /* :after is downward carrot */
  .jw-slider-volume, .jw-time-tip, .jw-time-tip:after {
    background-color: $white;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  .jw-tooltip-time {
    .jw-text {
      font-size: .8rem;
    }
  }
  .jw-controlbar {
    padding-top: 4px;
  }

  /* inexplicable overlays */
  .jw-title, .jw-preview {
    // display: none;
  }

  // JWPlayer tries to hide these on small bpoint
  .jw-text-elapsed, .jw-text-duration {
    display: inline-block!important;
  }
  &.jw-state-idle {
    .jw-display-icon-container {
      display: none;
    }
    .jw-text-elapsed, .jw-text-duration {
      display: none!important;
    }
  }
  .jw-slider-time {
    margin-top: 5px;
  }
  .jw-button-container .jw-icon {
    height: 30px;
    margin-top: 6px;
    margin-bottom: 3px;
  }

  .jw-controlbar {
    background: $white!important;
  }
  .jw-overlays.jw-reset {
    display: none;
  }

  /* Small breakpoint */
  // jw-breakpoint-0 jw-flag-small-player
  &.jw-breakpoint--1 .jw-controlbar {
    flex-direction: column!important;
  }

  &.jw-flag-small-player {
    .jw-controlbar {
      height: $player-height;
      padding-top: 4px;
      padding-bottom: 2px;

      .jw-button-container {
        // align volume/time to left
        padding-left: 0;
      }
    }
    // Align
    .jw-icon-inline.jw-text {
      line-height: 1rem;
    }
  }
  &.jw-state-idle,
  &.jw-flag-user-inactive {
    .jw-controls {
      background-color: $white!important;
    }
    .jw-display, .jw-controlbar {
      visibility: visible!important;
      opacity: 1;
    }
    .jw-controlbar {
      display: flex;
    }
  }
  .jw-settings-menu {
    height: auto!important; /* Otherwise it's set to 100% for small player */
    z-index: 100;
  }
  .jw-icon-cc {
    display: none;
  }
}

.video_player {
  max-width: 100%;
  > .jwplayer {
    max-width: 100%;
  }
  .jw-slider-time .jw-cue {
    background-color: rgba($red, 0.8)!important;
  }
}
.media_feature_player {
  .audio_only {
    .jwplayer {
      max-height: 40px;
    }
    margin-bottom: 1rem
  }
}

.full_program_video {
  .jwplayer {
    .jw-title {
      display: block!important;
      font-size: 80%;
    }
  }
}

/* For native full-screen */
.ios_video {
  video::cue {
    font-size: 50px!important;
    opacity: 1;
    background-color: black;
  }

  video::-webkit-media-text-track-display-backdrop {
    background-color: black !important;
    overflow: visible !important;
  }

  video::-webkit-media-text-track-display {
    overflow: visible !important;
    -webkit-box-sizing: border-box;
  }

  video::-webkit-media-text-track-container {
    overflow: visible !important;
  }
}

.android_video {
  video::-webkit-media-text-track-display {
    font-size: 20px!important;
    line-height: 1.4em!important;
  }
  .jw-text-track-display {
    font-size: 20px!important;
    line-height: 1.4em!important;
  }
}